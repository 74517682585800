import Tobii from '@midzer/tobii';

// More info at https://github.com/midzer/tobii
const tobii = new Tobii({
    captions: false,
    draggable: false,
    nav: false,
    close: true,
    counter: false,
    kayboard: true
  })
