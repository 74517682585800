import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

const mobileNav = document.getElementById("nav-mobile")
const navButtons = document.querySelectorAll(".nav-mobile__button")
const trigger = document.getElementById("nav-mobile__trigger")

navButtons.forEach((button) => {
    button.addEventListener("click", () => {
        if (button.getAttribute("aria-expanded") === "false") {
            button.setAttribute("aria-expanded", "true")
        } else {
            button.setAttribute("aria-expanded", "false")
        }
    })
})

trigger.addEventListener("click", () => {
    if (trigger.getAttribute("aria-expanded") === "false") {
        trigger.setAttribute("aria-expanded", "true")
        mobileNav.setAttribute("aria-hidden", "false")
        disableBodyScroll(mobileNav)
    } else {
        trigger.setAttribute("aria-expanded", "false")
        mobileNav.setAttribute("aria-hidden", "true")
        enableBodyScroll(mobileNav)
    }
})
