const showOnPx = 130
const backToTopButton = document.querySelector('.button__back-to-top')

const scrollContainer = () => {
    return document.documentElement || document.body
}

const goToTop = () => {
    document.body.scrollIntoView({
        behavior: 'smooth',
    })
}

document.addEventListener('scroll', () => {
    if (scrollContainer().scrollTop > showOnPx) {
        backToTopButton.classList.remove('hidden')
    } else {
        backToTopButton.classList.add('hidden')
    }
})

backToTopButton.addEventListener('click', goToTop)
